
























































import Vue from "vue";

import Spinners from "@/components/utilities/Spinners.vue";
import MgImage from "@/components/shared/MgImage.vue";

import { TradeshowCompanyObject, SessionDataObject } from "@/types/interfaces";

import { subMinutes, differenceInSeconds } from "date-fns";

import {
    sessionHelpSessionIsActive,
    sessionHelpEarlyAccessMinutes
} from "@/services/session-helpers";

export default Vue.extend({
    data() {
        return {
            isLoadingHeroImage: false
        };
    },
    components: {
        Spinners,
        MgImage
    },
    props: {
        companyData: {
            type: Object,
            required: true,
            default() {
                return {} as TradeshowCompanyObject;
            }
        },
        mySession: {
            type: Object,
            required: true,
            default() {
                return {} as SessionDataObject;
            }
        },
        comID: {
            type: String,
            default: ""
        },
        isSessionActive: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        // Getters

        // get ses() {
        //     return this.mySession;
        // }
        companyApiOptions(): Record<string, string> {
            const options = { id: this.comID };
            return options;
        },

        layoutImagePagePrimary(): string {
            let returnvalue = this.$store.getters.getPageOptions("tradeshow")
                .defaultHero;

            if (returnvalue) {
                returnvalue = `/bg/${returnvalue}`;
            } else {
                returnvalue = "";
            }

            return returnvalue;
        },

        heroImage(): string | File {
            let returnValue = "";
            const hero = this.companyData?.images?.hero;

            if (hero && hero["800x147"]) {
                const imgUrl = hero["800x147"];
                if (imgUrl) {
                    returnValue = imgUrl;
                }
            } else if (this.layoutImagePagePrimary) {
                returnValue = this.layoutImagePagePrimary;
            }

            return returnValue;
        },

        imgSrcset() {
            const exhibitor = this.companyData;
            let returnPath = "";
            const set = [];

            if (exhibitor?.images?.hero) {
                const urls = exhibitor.images.hero;
                const imgSm = urls["2000x368"];
                const imgLg = urls["800x147"];

                if (imgSm) {
                    set.push(`${imgSm} 600w`);
                }

                if (imgLg) {
                    set.push(`${imgLg} 2048w`);
                }

                returnPath = set.join(",");
            }

            return returnPath;
        },
        handleActiveSessionCheck(): boolean {
            const session: any = this.mySession;
            return sessionHelpSessionIsActive(session);
        },

        pageConfig(): Record<string, any> {
            return this.$store.getters.getPageOptions("tradeshow") || {};
        },
        sessionEarlyAccessMinutes(): number {
            const session = this.mySession;
            const returnValue = sessionHelpEarlyAccessMinutes(session);

            return returnValue;
        },
        myStartTime(): string {
            const returnValue = this.mySession.startTime;
            return returnValue;
        },
        sessionDateTime(): Date {
            const iso = this.myStartTime;
            return new Date(iso);
        },
        startTimeAdjusted(): Date {
            const earlyMinutes = this.sessionEarlyAccessMinutes;
            const startTime = this.sessionDateTime;

            return subMinutes(startTime, earlyMinutes);
        }
    },
    methods: {
        // Methods
        getImgPath(exhibitor: TradeshowCompanyObject) {
            const logo = exhibitor?.images?.logo;
            let returnPath = "";
            if (logo && "string" === typeof logo) {
                returnPath = logo;
            } else if (exhibitor.imageLogo) {
                returnPath = exhibitor.imageLogo;
            } else {
                returnPath = `/bg/${this.pageConfig.defaultLogo}`;
            }

            return returnPath;
        },

        getImgSrcset(exhibitor: TradeshowCompanyObject) {
            let returnPath = "";
            const set = [];

            if (exhibitor?.images?.logo) {
                const urls = exhibitor.images.logo;
                const img400 = urls["400x400"];
                const img800 = urls["800x800"];

                if (img400) {
                    set.push(`${img400} 600w`);
                }

                if (img800) {
                    set.push(`${img800} 2048w`);
                }

                returnPath = set.join(",");
            }

            return returnPath;
        },
        openCompanyProfile(id: string) {
            this.$router.push(`/tradeshow/exhibitor-details/${id}`);
        },

        // gotToMeeting(id: string) {
        //     this.$router.push({
        //         path: `/meeting/SESSION${id}`
        //     });
        // }

        gotToMeeting() {
            const url = this.mySession.url;

            if (url) {
                let isUrl = false;
                try {
                    new URL(url);
                    isUrl = true;
                } catch (error) {
                    // no need to log error
                }

                if (isUrl) {
                    window.open(url, undefined, "noopener,noreferrer");
                } else {
                    this.$router.push(url);
                }
            } else if (this.mySession.speakeasyType) {
                this.$router.push({
                    path: `/meeting/SESSION${this.mySession.sessionId}`
                });
            } else {
                const startTime = this.startTimeAdjusted;
                const timeSinceStart = differenceInSeconds(
                    window.MgServerTime,
                    startTime
                );

                this.$router.push({
                    name: "Session",
                    params: {
                        id: this.mySession.sessionId,
                        startVideoAt: `${timeSinceStart}`
                    }
                });
            }
        }
    }
});
