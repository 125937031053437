/**
 * NOTE:
    - These are meant to return values only.
    - Let's avoid calls to vuex mutations / actions from within these functions.
 *
 * For best performance pass in local variables instead of component variables, example:
 
    // WRONG (In component):
    sessionHelpIsPresenter(this.myId, this.session)
 
    // CORRECT (In component):
    const myId = this.myId;
    const session = this.session;
    sessionHelpIsPresenter(myId, session)
 */
import store from "@/store";
import {
    AttendeeBase,
    AttendeeScheduleSlot,
    SessionDataObject,
    PosterObject
} from "@/types/interfaces";
import {
    parse,
    startOfToday,
    isWithinInterval,
    differenceInSeconds,
    subMinutes,
    isBefore,
    isAfter
} from "date-fns";

/**
 * Check if user is a host of a session
 *
 * @param user
 * @param session
 * @returns {Boolean}
 */
export const sessionHelpIsHost = function(
    user: AttendeeBase,
    session: AttendeeScheduleSlot | SessionDataObject | PosterObject
) {
    let returnValue = false;

    if (user.id && session?.networking?.hostId) {
        returnValue = Boolean(user.id === session.networking.hostId);
    }

    return returnValue;
};

/**
 * Check if an ID exists in sessions presenters
 *
 * @param id - attendee ID
 * @param session
 * @returns {Boolean}
 */
export const sessionHelpIsPresenter = function(
    id = "",
    session: AttendeeScheduleSlot | SessionDataObject | PosterObject
) {
    const presenters = session.presenters;
    const returnValue = Array.isArray(presenters) && presenters.includes(id);

    return returnValue;
};

export const sessionHelpEarlyAccessMinutes = function(
    session: AttendeeScheduleSlot | SessionDataObject | PosterObject
) {
    let returnValue = store.state.settingsVuexModule.sessionEarlyEntryMinutes;

    const user = store.getters.userInfo;
    const id = user?.id;
    let isPosterOwner = false;

    if ("poster_attendees" in session && Array.isArray(session.attendeeIds)) {
        isPosterOwner = session.attendeeIds.includes(id);
    }

    if (
        user.superuser ||
        sessionHelpIsPresenter(id, session) ||
        isPosterOwner
    ) {
        returnValue = 30;
    }

    return returnValue;
};

/**
 * Is a session active?
 *
 * @param session
 * @returns {Boolean}
 */
export const sessionHelpSessionIsActive = function(
    session: AttendeeScheduleSlot | SessionDataObject | PosterObject
) {
    let returnValue = false;

    if (!session || !session.startTime) return false;

    const startTime = new Date(session.startTime);
    const endTime = new Date(session.endTime);

    // hosts can enter the meeting earlier than others
    const user = store.getters.userInfo;
    const hostMins =
        store.state.settingsVuexModule.hostAppointmentEarlyEntryMinutes;
    const nonHostMins =
        store.state.settingsVuexModule.appointmentEarlyEntryMinutes;

    let earlyMinutes = sessionHelpIsHost(user, session)
        ? hostMins
        : nonHostMins;

    const itemType = session.type || "";
    const isAppointmentType = ["scheduler", "appointments"].includes(itemType);

    if (!isAppointmentType) {
        earlyMinutes = sessionHelpEarlyAccessMinutes(session);
    }

    const startTimeAdjusted = subMinutes(startTime, earlyMinutes);

    const timeSinceAdjusted = differenceInSeconds(
        window.MgServerTime,
        startTimeAdjusted
    );
    const hasStarted = Boolean(timeSinceAdjusted > -1);

    const isActive = isWithinInterval(window.MgServerTime, {
        start: startTimeAdjusted,
        end: endTime
    });

    returnValue = hasStarted && isActive;

    return returnValue;
};

/**
 * Is a session over?
 *
 * @param session
 * @returns {Boolean}
 */
export const sessionHelpSessionIsOver = function(
    session: AttendeeScheduleSlot | SessionDataObject | PosterObject
) {
    let returnValue = false;

    if (!session.startTime && !session.endTime) {
        return true;
    }

    const endTime = new Date(session.endTime);

    const today = startOfToday();
    const rightNow = window.MgServerTime;

    const date = parse(session.startTime, "yyyy-MM-dd", rightNow);

    if (isBefore(date, today)) {
        returnValue = true;
    } else if (isAfter(date, today)) {
        returnValue = false;
    } else {
        returnValue = isBefore(endTime, rightNow);
    }

    return returnValue;
};
