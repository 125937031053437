<template>
    <!--
        Not using <b-image> as it is not so flexible.
        For example the `srcset-sizes` expects an array and prevents us from
        using sizes="100vw" as a reference point for all srcset values.
        Furthermore it wraps all images in <figure> which will add overhead to styling / restyle existing imagery,
        and could potentially be problematic down the road.
        Lastly, the `lazy` option in `b-image` component works but seems to have a high `rootMargin` Intersection observer value,
        our `v-lazyload` directive works better.
     -->
    <img
        v-if="lazy"
        :ref="imgId"
        :data-src="sourceUrl"
        :data-srcset="srcsetString"
        :alt="alt"
        sizes="100vw"
        v-lazyload
        class="swiper-lazy"
    />
    <img
        v-else
        :ref="imgId"
        :src="sourceUrl"
        :srcset="srcsetString"
        :alt="alt"
        sizes="100vw"
    />
</template>

<script>
/**
 * By default this component assumes your source imagery
 * is protected and will use a cloudfront domain with a signed parameter to make the request.
 * If you want to use images which are open to the world use the `public` prop,
 * this will leave the source urls passed in as is.
 */

import { getModule } from "vuex-module-decorators";
import signedUrlVuexModule from "@/store/vuex-modules/signed-url";
const signedUrlStore = getModule(signedUrlVuexModule);

export default {
    name: "mg-image",
    props: {
        src: {
            type: String,
            required: false,
            default: ""
        },
        srcset: {
            type: String,
            required: false,
            default: ""
        },
        alt: {
            type: String,
            default: ""
        },
        public: {
            type: Boolean
        },
        /**
         * Lazy images are better for loading times, but if the image has to change at some point
         * it won't work properly if it's set up to be lazy. So set this to false if your image
         * might need to dynamically update.
         */
        lazy: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            imgId: `img-${this.MgGetUUID()}`,
            imgError: false,
            sourceUrl: "",
            srcsetString: ""
        };
    },
    computed: {
        loadError() {
            const condition1 = !this.sourceUrl && !this.srcsetString;
            const condition2 = this.sourceUrl && this.imgError;
            const condition3 = this.srcsetString && this.imgError;
            return [condition1, condition2, condition3].some((value) => value);
        }
    },
    watch: {
        src() {
            this.loadImage();
        }
    },
    mounted() {
        this.loadImage();
    },
    methods: {
        async loadImage() {
            const img = this.$refs[this.imgId];
            this.sourceUrl = this.getSourceUrl();
            this.srcsetString = this.getSrcsetString();

            /**
             * Note: not using `onload` `onerror` attributes on `img` tag
             * doing that seems to always trigger an error even when src and srcset get valid values.
             * Doing the following in js on `mounted` seems to work as expected.
             */

            if (img) {
                img.onload = () => {
                    // n/a
                };

                img.onerror = () => {
                    /**
                     my original intention was to display a fallback div on image error like so:

                    <div v-if="loadError" aria-hidden="true"></div>
                    <img v-else>

                    however this is problematic with `srcset`.
                    example:
                    `onerror` will only fire if initial load tries a faulty img address.
                    `onerror` will NOT fire if:
                    1. the intial srcset image loads fine
                    2. the viewport changes and the new srcset image fails
                 */
                };
            } else {
                console.log("no img");
            }
        },
        getSourceUrl() {
            let returnValue = "";
            const src = this.src;

            if (!this.public) {
                returnValue = signedUrlStore.getSignedResourceUrl(src);
            }

            return returnValue;
        },
        getSrcsetString() {
            let returnValue = "";

            if (this.public) {
                returnValue = this.srcset;
            } else if (this.srcset) {
                const parts = this.srcset.split(",").map((part) => {
                    let url = part.split(" ")[0];
                    const size = part.split(" ")[1];
                    url = signedUrlStore.getSignedResourceUrl(url);
                    return `${url} ${size}`;
                });
                returnValue = parts.join(",");
            }

            return returnValue;
        }
    }
};
</script>
